import { Link } from 'gatsby'
import React from 'react'
import get from 'lodash/get'

import Meta from 'components/Meta'
import Layout from 'components/Layout'

const Page404 = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Meta site={get(data, 'site.meta')} />
      <div className="st-content">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div
                id="primary"
                className="content-area padding-content white-color"
              >
                <main id="main" className="site-main">
                  <section className="error-404 not-found text-center">
                    <h2 className="404">404</h2>
                    <p className="lead">
                      Sorry, we could not found the page you are looking for!
                    </p>
                    <div className="row">
                      <div className="col-sm-4 col-sm-offset-4">
                        <p className="go-back-home">
                          <Link className="readmore" to="/">
                            Back to Home Page
                          </Link>
                        </p>
                      </div>
                    </div>
                  </section>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page404
